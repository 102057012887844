const _COLORS = [
  "#C91F37",
  "#D24D57",
  "#E68364",
  "#8F1D21",
  "#F47983",
  "#DB5A6B",
  "#C93756",
  "#F58F84",
  "#875F9A",
  "#5D3F6A",
  "#763568",
  "#9B59B6",
  "#8E44AD",
  "#5D8CAE",
  "#22A7F0",
  "#59ABE3",
  "#48929B",
  "#317589",
  "#4B77BE",
  "#1F4788",
  "#003171",
  "#044F67",
  "#264348",
  "#7A942E",
  "#8DB255",
  "#5B8930",
  "#6B9362",
  "#407A52",
  "#006442",
  "#26A65B",
  "#26C281",
  "#049372",
  "#D9B611",
  "#F3C13A",
  "#E2B13C",
  "#A17917",
  "#FFA400",
  "#E08A1E",
  "#F9690E",
  "#CA6924",
  "#BFBFBF",
  "#757D75",
  "#95A5A6",
  "#6C7A89"
]

export default _COLORS.sort(() => {
  return Math.random()
})
